import Box, { Content, Section } from 'components/box'
import convertImageMap from 'helpers/convertImageMap'
import Img from 'gatsby-image'
import Layout from 'components/layout'
import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'gatsby'
import { Heading, Lead } from 'components/text'
import { Link as GatsbyLink } from 'gatsby'
import { SecondaryButton } from 'components/button'

const Sent = ({ data }) => {
  const { page } = data
  const icons = convertImageMap(page.icons)

  return (
    <Layout pageTitle="Message sent">
      <Section>
        <Content textAlign="center" pb={6} pt={5}>
          <Box size="10rem" mx="auto">
            <Img {...icons['Sent']} />
          </Box>
          <Heading level={1} mt={2}>
            Message sent
          </Heading>
          <Lead
            color="neutral-500"
            fontWeight="medium"
            measure="normal"
            mb={4}
            mt={1}
            mx="auto"
          >
            Your message is on its way. We'll get back to you as soon as we can.
          </Lead>
          <SecondaryButton as={GatsbyLink} to="/" size="large" mb={3}>
            Go back home
          </SecondaryButton>
        </Content>
      </Section>
    </Layout>
  )
}

Sent.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Sent

export const query = graphql`
  query SentQuery {
    page: metaJson {
      icons {
        title
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
